<template>
	<div class="_w-max">
		<div class="li-1 mb-mar-top-30 mb-pad-x-15">
			<div class="title mb-font-15">{{ $t('包裹单号') }}</div>
			<input type="text" class="pack-number-input pc-w-760 mb-w-max mb-font-15 mb-pad-x-15" :placeholder="$t('请输入')" v-model="expresssn">
			<!-- <textarea class="textarea-box" :placeholder="'每行输入一个单号，可进行批量查询...'"></textarea> -->
		</div>
		<div class="li-2 mb-pad-x-15">
			<div class="title mb-font-15">{{ $t('货物类别') }}: {{category_name || ''}}</div>
			<div class="radio-list mb-radio-list">
				<div class="radio-item mb-mar-0 mb-radio-item mb-flex-row-mid" style="align-items: center;" v-for="(item,index) in radioType" :key="index">
					<input class="radio_type " type="radio" :id="item.id" :value="item" :checked="item.id==radio_index" @change="radioChange" style="cursor: pointer;"> 
					<label v-if="lang=='zh_cn'" :for="item.id" style="color: #333;cursor: pointer;">{{item.name}}</label>
					<label v-if="lang=='en_us'" :for="item.id" style="color: #333;cursor: pointer;">{{item.name_us}}</label>
					<label v-if="lang=='ru'" :for="item.id" style="color: #333;cursor: pointer;">{{item.name_ru}}</label>
				</div>
			</div>
		</div>
		<div class="li-2 mb-pad-x-15">
			<div class="title mb-font-15">{{ $t('是否打木架') }}</div>
			<div class="radio-list mb-radio-list">
				<div class="radio-item mb-flex-row-mid mb-font-15">
					<input class="radio_type" type="radio" :id="1" :value="1" :checked="is_reinforce==1" @change="radioChangeReinforce" style="cursor: pointer;"> 
					<label :for="1" style="color: #333;cursor: pointer;">{{ $t('yes') }}</label>
				</div>
				<div class="radio-item mb-flex-row-mid mb-font-15">
					<input class="radio_type" type="radio" :id="2" :value="0" :checked="is_reinforce==0" @change="radioChangeReinforce" style="cursor: pointer;"> 
					<label :for="2" style="color: #333;cursor: pointer;">{{ $t('no') }}</label>
				</div>
			</div>
		</div>
		<div class="li-1 mb-pad-x-15" style="margin-top: 20px;">
			<div class="title mb-font-15">{{ $t('物品名称') }}</div>
			<input type="text" class="pack-number-input pc-w-760 mb-w-max mb-font-15 mb-pad-x-15" :placeholder="$t('请输入')" v-model="goods_name">
		</div>
		<div class="li-1 mb-pad-x-15" style="margin-top: 20px;">
			<div class="title mb-font-15">{{ $t('申报价值') }}</div>
			<input type="text" class="pack-number-input pc-w-760 mb-w-max mb-font-15 mb-pad-x-15" :placeholder="$t('请输入')" v-model="goods_money">
		</div>
		<div class="li-1 mb-pad-x-15" style="margin-top: 20px;">
			<div class="title mb-font-15">{{ $t('包裹备注') }}</div>
			<textarea class="textarea-box pc-w-760 mb-w-max mb-font-15 mb-pad-0 mb-pad-y-15 mb-pad-x-15" style="min-height: 100px" :placeholder="$t('请输入包裹备注')" v-model="report_remark"></textarea>
		</div>
		<div class="li-3 pc-w-445 mb-mar-0 mb-pad-x-15 mb-w-max display-none" @click="submit">{{ $t('添加包裹') }}</div>
		<div class="mb-w-max mb-pad-x-15 _pc-display-none">
			<div class="li-3 pc-w-445 mb-mar-0 mb-w-max" style="height: 2.8125rem;font-size: 0.9375rem;margin-top: 1.25rem;" @click="submit">{{ $t('添加包裹') }}</div>
		</div>
	</div>
</template>

<script>
	import { getCategory, additionReport } from '@/api/jiyun';
	import { getCookies } from "@/libs/util.js";
	export default {
		name:'OnePack',
		data() {
			return {
				radioType: [],
				radio_index:0,
				expresssn: '',
				goods_name: '',
				top: 0,
				report_remark: '',
				category_name: '',
				goods_money: '',
				is_reinforce: 0,
				lang: 'zh_cn',
			}
		},
		created(){
			this.lang = localStorage.getItem('think-lang') || 'zh_cn';
			this.top = this.$route.query.top-0;
			let token = getCookies('web-token');
			if(token){
				this.getCategory();
			}else{
				this.$Message.warning(this.$t('请先登录'));
				this.$router.push('/login');
			}
		},
		methods: {
			// 提交
			submit(){
				if(!this.expresssn) return this.$Message.error(this.$t('请输入快递单号'))
				let param = {
					expresssn: this.expresssn,
					goods_name: this.goods_name,
					cate_id: this.radio_index,
					domain_url: window.location.origin,
					report_remark: this.report_remark,
					goods_money: this.goods_money,
					is_reinforce: this.is_reinforce
				}
				additionReport(param).then((res)=>{
					this.radio_index = 0;
					this.expresssn = '';
					this.goods_name = '';
					this.category_name = '';
					this.report_remark = '';
					this.goods_money = '';
					this.is_reinforce = 0;
					this.$Message.success(this.$t('报备成功'))
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 货物分类
			getCategory(){
				getCategory().then((res)=>{
					this.radioType = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
				
			},
			// 选择分类
			radioChange:function(e) {
				this.radio_index = e.target._value.id
				this.category_name = e.target._value.name
			},
			// 是否打木架
			radioChangeReinforce(evt){
				this.is_reinforce = evt.target._value;
			},
		}
	}
</script>


<style scoped>
	.radio_type{
		width: 20px;
		height: 20px;
		appearance: none;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
	}
	.radio_type:before{
		content: '';
		width: 20px;
		height: 20px;
		border: 1px solid #7d7d7d;
		display: inline-block;
		border-radius: 50%;
		vertical-align: middle;
	}
	.radio_type:checked:before{
		content: '';
		width: 20px;
		height: 20px;
		border: 1px solid #4284DC;
		background:#4284DC;
		display: inline-block;
		border-radius: 50%;
		vertical-align: middle;
	}

	.title {
		margin-bottom: 10px;
		font-size: 18px;
	}
	.li-1 {
		color: #333;
		width: 100%;
	}
	.li-1 .pack-number-input {
		
		height: 52px;
		border-radius: 14px;
		border: 1px solid #d6d6d6;
		background-color: #f7f7f7;
		outline: none;
		padding: 0px 25px;
		display: flex;
		align-items: center;
		font-size: 18px;
	}
	.li-1 .textarea-box {
		
		min-height: 305px;
		border-radius: 14px;
		background: #F7F7F7;
		border: 1px solid #D6D6D6;
		padding: 25px;
		font-size: 20px;
		line-height: 1.4;
		outline: none;
	}
	.li-1 .textarea-box::placeholder {
		color: #999;
	}
	.li-2 {
		margin-top: 18px;
		color: #333;
		width: 100%;
	}
	.tips-box {
		margin-top: 37px;
		width: 760px;
		border-radius: 14px;
		padding: 22px 27px;
		border: 1px solid #4284DC;
		background: rgba(66,132,220,0.15);
		display: flex;
		align-items: center;
	}

	.tips-box .tips-check {
		width: 18px;
		height: 18px;
	}
	.tips-box .text {
		margin-left: 22px;
		font-size: 20px;
		line-height: 1.7;
		color: #4284DC;
	}
	.tips-check{
		width: 20px;
		height: 20px;
		appearance: none;
		position: relative;
		margin-right: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.tips-check:before{
		content: '';
		width: 20px;
		height: 20px;
		border: 1px solid #7d7d7d;
		display: inline-block;
		border-radius: 4px;
		vertical-align: middle;
	}
	.tips-check:checked:before{
		content: '';
		width: 20px;
		height: 20px;
		border: 1px solid #4284DC;
		background:#4284DC;
		display: inline-block;
		border-radius: 4px;
		vertical-align: middle;
	}
	.tips-check:checked:after{
		content: '';
		width: 10px;
		height:5px;
		border: 2px solid white;
		border-top: transparent;
		border-right: transparent;
		text-align: center;
		display: block;
		position: absolute;
		/* top: 6px;
		left:5px; */
		vertical-align: middle;
		transform: rotate(-45deg);
	}
	.li-3 {
		margin-top: 115px;
		margin-left: 130px;
		height: 68px;
		font-size: 24px;
		color: #fff;
		background: #4284DC;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 12px;
		cursor: pointer;
	}
	.li-3:hover {
		opacity: 0.4;
	}
	.pc-w-760 {
		width: 760px;
	}
	.pc-w-445 {
		width: 445px;
	}
	@media screen and (min-width: 768px){
		.li-2 .radio-list {
			margin-top: 20px;
			padding: 0px 0px;
			width: 100%;
			display: flex;
		}
		.li-2 .radio-list .radio-item {
			display: flex;
			/* align-items: center; */
			margin-right: 65px;
			font-size: 20px;
		}
		.radio_type:checked:after{
			content: '';
			width: 10px;
			height:5px;
			border: 2px solid white;
			border-top: transparent;
			border-right: transparent;
			text-align: center;
			display: block;
			position: absolute;
			/* top: 14px;
			left:5px; */
			vertical-align: middle;
			transform: rotate(-45deg);
		}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0 !important;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem !important;
			padding-right: 0.9375rem !important;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem !important;
			padding-bottom: 0.9375rem !important;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem !important;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.mb-flex-row-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
		.mb-main-box {
			width: 100%;
			background: #fff;
			padding-bottom: 1.875rem;
		}
		.mb-main-box .mb-main-box-nav {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			border-bottom: 1px solid #e2e2e2;
		}
		.mb-main-box .mb-main-box-nav .mb-li {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0.5rem 0rem;
			font-size: 0.9375rem;
		}
		.mb-main-box .mb-main-box-nav .mb-li-select {
			color: #4284DC;
			font-weight: bold;
			position: relative;
		}
		.mb-main-box .mb-main-box-nav .mb-li-select::after {
			content: '';
			position: absolute;
			bottom: -1px;
			width: 16%;
			height: 0.125rem;
			background-color: #4284DC;
		}
		.mb-radio-list {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		.radio_type:checked:after{
			content: '';
			width: 10px;
			height:5px;
			border: 2px solid white;
			border-top: transparent;
			border-right: transparent;
			text-align: center;
			display: block;
			position: absolute;
			/* top: 0.4375rem;
			left:5px; */
			vertical-align: middle;
			transform: rotate(-45deg);
		}
	}
</style>